import { ApiConfig } from '@src/app/_global/domain/apiGateway';
var apigClientFactory = require('aws-api-gateway-client').default;
import { CustomResponse } from '@CDSGlobal/gc-backend';
import { CognitoIdentityClient, GetCredentialsForIdentityCommand } from '@aws-sdk/client-cognito-identity';

export class ApiClient {
  getSessionStorageValue = (value: string): {} | void => {
    var valueString = sessionStorage.getItem(value);
    if (valueString) {
      return JSON.parse(valueString);
    }
  }
  callApiGateway = (config: ApiConfig): Promise<any | void> => {
    return new Promise((resolve, reject) => {
      var theData;
      if (config.credentialsObject) {
        const client = new CognitoIdentityClient({region: config.region});
        const command = new GetCredentialsForIdentityCommand(config.credentialsObject);
        client.send(command, async (err: any, creds: any) => {
          if (err) {
            console.log(err);
            reject(err);
          } else {
            var apigClient = apigClientFactory.newClient({
              accessKey: creds.Credentials.AccessKeyId,
              secretKey: creds.Credentials.SecretKey,
              sessionToken: creds.Credentials.SessionToken,
              region: config.region,
              invokeUrl: config.url,
            });
            const result = await apigClient
              .invokeApi(
                config.parms,
                config.path,
                config.requestmethod,
                config.additionalParms,
                config.requestbody,
              )
              // tslint:disable-next-line:no-any
              .then(function(iamResult: any) {
                if (iamResult.data) {
                  if (iamResult.data.prodId) {
                    let response = iamResult.data as CustomResponse;
                    // theData = response;
                    // console.log('theData', theData);
                    return response;
                  } else {
                    // console.log('no prodId');
                    return iamResult.data;
                  }
                } else {
                  console.log('no result.data');
                  return undefined;
                }
              })
              .catch(function(data: any) {
                var responseData = data.message;
                console.error('IAM error result: ', responseData);
                console.error(data.stack);
                return responseData;
              });
            resolve(result);
          }
        });
      } else {
        reject('no config creds');
      }
    });
  }
}
