import { SendEmailConfig } from '@src/app/_global/domain/sesEmailConfig';
import { CognitoIdentityClient, GetIdCommand } from '@aws-sdk/client-cognito-identity';
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses';
import { fromCognitoIdentity } from '@aws-sdk/credential-providers';
export class SendEmailClient {
  getSessionStorageValue = (value: string): {} | void => {
    var valueString = sessionStorage.getItem(value);
    if (valueString) {
      return JSON.parse(valueString);
    }
  }
  sendEmail = (config: SendEmailConfig): Promise<any | void> => {
    return new Promise((resolve, reject) => {
      const credentialsObject = {
        IdentityPoolId: config.identityPoolId,
      };
      const client = new CognitoIdentityClient({region: config.region});
      const command = new GetIdCommand(credentialsObject);

      client.send(command, async (credsErr: any, creds: any) => {
        if (credsErr) {
          console.log(credsErr);
          reject(credsErr);
        } else {
          // Create sendEmail params 
          const params = {
            Destination: { /* required */
              ToAddresses: [
                config.emailFields.to,
              ]
            },
            Message: { /* required */
              Body: { /* required */
                Text: {
                  Charset: 'UTF-8',
                  Data: config.emailFields.message
                }
              },
              Subject: {
                Charset: 'UTF-8',
                Data: config.emailFields.subject
              }
            },
            Source: config.emailFields.from, /* required */
            ReplyToAddresses: [
              config.emailFields.replyTo
            ],
          };

          const identitiyIdForClient = config.credentialsObject && config.credentialsObject.IdentityId ? config.credentialsObject.IdentityId : creds.IdentityId;
          const loginsForClient = config.credentialsObject && config.credentialsObject.Logins ? config.credentialsObject.Logins : '';

          const sesClient = new SESClient({ region: config.region,
            credentials: fromCognitoIdentity({ 
              identityId: identitiyIdForClient,
              logins: loginsForClient,
              clientConfig: { region: config.region },
            })
          });

          const sendEmailCommand = new SendEmailCommand(params);

          var sendPromise = await sesClient.send(sendEmailCommand).then(
            function(data: any) {
              let emailUUID = params.Message.Body.Text.Data.slice(params.Message.Body.Text.Data.indexOf('GC Email Session'));
              console.error('GC Email SES messageId: ' + data.MessageId);
              console.error(emailUUID);
              return(data);
            }).catch(
              function(err: any) {
              console.error(err, err.stack);
              return (err);
            });
            resolve(sendPromise);
        }
      });
    });
  }
}
