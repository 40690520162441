import { createSelector } from 'reselect';
import { RootState } from '@src/app/_redux';
import { CustomCustomer } from '@CDSGlobal/gc-backend';
import { State as AccountState } from '@src/app/accounts/redux/reducers';
import {
  ConfigState,
  AdminConfigState,
} from '@src/app/main/reducers';
import { getAdminConfigTableItem } from '@src/app/_global/utility/adminConfigUtils';
export const getAccount = ({ account }: RootState) => {
  return account;
};
export const getClientConfig = ({ clientConfig }: RootState) => {
  return clientConfig;
};
export const getAdminConfig = ({ adminConfig }: RootState) => {
  return adminConfig;
};
export const associatedAccounts = ({ account, adminConfig }: RootState) => {
  if (account.customerData.customers) {
    return account.customerData.customers.map(
      ({ productName, accountNumber, prodIdAlias, email, activeSubscriptions , address, systemCode}) => {
        let clientCode = '';
        let tableName = 'ClientProperties';
        if (adminConfig.adminConfig[prodIdAlias]) {
          clientCode = adminConfig.adminConfig[prodIdAlias][tableName].ClientCode;
        }
        return { name: productName, accountNumber, prodIdAlias, email, address, activeSubscriptions, systemCode, clientCode };
      },
    );
  }
  return null;
};

export const getAssociatedAccounts = createSelector(
  associatedAccounts,
  (accounts: any = []) => {
    return accounts;
  },
);

export const getDedupedAssociatedAccounts = createSelector(
  associatedAccounts,
  (accounts: any) => {
    return accounts.filter((payload, index, self) => {
      return self.findIndex(t => t.name === payload.name) === index;
    });
  },
);
export const getAssociatedGiftAccounts = createSelector(
  getAccount,
  (account: any) => {
    let associatedGiftAccounts;
    if (account.associatedGiftAccountsMap) {
      associatedGiftAccounts =
        account.associatedGiftAccountsMap.associatedGiftAccounts;
    }
    return associatedGiftAccounts;
  },
);
export const getAllPaymentMethods = createSelector(
  getAccount,
  (account: any) => {
    let paymentMethods;
    if (account.paymentMethodsData) {
      paymentMethods =
        account.paymentMethodsData.paymentMethods;
    }
    return paymentMethods;
  },
);
export const getPaymentMethodsNeedsAttention = createSelector(
  getAccount,
  (account: any) => {
    let paymentMethods;
    if (account.paymentMethodsData) {
      paymentMethods =
        account.paymentMethodsData.paymentMethods;
    }
    return paymentMethods.filter((pm) => {return pm.needsAttention && pm.associatedAccounts.length > 0; });
  },
);
export const getPaymentMethodsRegular = createSelector(
  getAccount,
  (account: any) => {
    let paymentMethods;
    if (account.paymentMethodsData) {
      paymentMethods =
        account.paymentMethodsData.paymentMethods;
    }
    return paymentMethods.filter((pm) => {
      return !pm.needsAttention && pm.associatedAccounts.length > 0; 
    });
  },
);
export const getLoggedInCustomer = createSelector(
  getAccount,
  (account: any) => {
    let cust;
    if (account && account.customerData && account.customerData.customers) {
      cust = <CustomCustomer> account.customerData.customers[0];
    }
    return cust;
  },
);

export const customerByOrderId = (state: RootState, orderNumberProp) => {
  const customers = getCustomers(state);
  const orders = getOrders(state);
  const order = orders.find(findOrder => findOrder.orderNumber === orderNumberProp);

  if (order && !order.isCore) {
    let orderAcctNumber = '';
    if (order.orderItems.length > 0) {
      orderAcctNumber = order.isGift  && order.isRecipient ? order.orderItems[0].shipToAccountNumber : order.orderItems[0].billToAccountNumber;
    }
    let orderCustomer =  customers.find(customer => { 
      return customer.accountNumber === orderAcctNumber && customer.prodIdAlias === order.prodIdAlias;
    });
    // SERV/Pf orders can be for different product line than the customer, so just match on client code if customer is not found. 
    if (!orderCustomer && order.isPf) {
      orderCustomer =  customers.find(customer => { 
        return customer.accountNumber === orderAcctNumber && customer.prodIdAlias.substring(0, 3) === order.prodIdAlias.substring(0, 3); 
      });
    }
    return orderCustomer;
  } else {
    return getLoggedInCustomer(state);
  }
};

export const getCustomerByOrderId = createSelector([customerByOrderId], customer => customer);

export const getLoggedInAddress = createSelector(
  getLoggedInCustomer,
  (customer: any) => {
    let address;
    return (address = customer.address || {});
  },
);

export const getOrders = createSelector(getAccount, (account: any) => {
  return account.orderData.orders;
});

export const suspendOrderById = (state: RootState, orderNumberProp) => {
  const orders = getOrders(state);
  if (orderNumberProp === 'ALL') {
    return orders.filter((order, index, self) => {
      let availableActions = getAdminConfigTableItem(state.adminConfig, state.clientConfig, 'AvailableActions', order.prodIdAlias);
      if (order.isPf) {
        return order.isDisplayedOnMySubs 
        && availableActions && availableActions.Preferences && availableActions.Preferences.enableSuspend 
        && order.isSuspendable 
        && self.findIndex(t =>  t.isSuspendable && t.prodIdAlias === order.prodIdAlias 
          && t.planCode === order.planCode 
          && t.accountNumber === order.accountNumber) === index; // dedupe pf
      } else {
        return order.isDisplayedOnMySubs 
          && availableActions && availableActions.Preferences && availableActions.Preferences.enableSuspend 
          && order.isSuspendable 
          && self.findIndex(t =>  t.isSuspendable && t.prodIdAlias === order.prodIdAlias ) === index; // dedupe mags
      }
    });
  } else {
    return orders.filter(order => order.orderNumber === orderNumberProp);
  }
};

export const getSuspendOrdersById = createSelector([suspendOrderById], order => order);

export const resumeOrderById = (state: RootState) => {
  const orders = getOrders(state);
  return orders.filter((order, index, self)  => { 
    return order.isResumable 
    && self.findIndex(t =>  t.isResumable && t.prodIdAlias === order.prodIdAlias 
    && t.planCode === order.planCode 
    && t.accountNumber === order.accountNumber) === index; // dedupe);
  });
};

export const getResumeOrdersById = createSelector([resumeOrderById], order => order);

export const resumedOrders = (state: RootState) => {
  // This will return a collection of the orders that were resumed.  
  // The order info will be the updated info after the 'refresh' GCF call, which happens after the Resume txn is processed.
  const orders = getOrders(state);
  if (state.account.resumeData.resumeSavedResult && !state.account.resumeData.resumeSavedResult.success) {
    return [];
  }
  let myResumedOrders = state.account.resumeData.resumeSavedResult ? state.account.resumeData.resumeSavedResult.payload : [];
  let submittedOrders = orders.filter(order => {
  // return orders.filter(order => {
    let foundOrder = myResumedOrders.find(resumePayload => { 
      return resumePayload.accountNumber === order.billToAccountNumber 
        && resumePayload.prodIdAlias === order.prodIdAlias 
        && resumePayload.planCode === order.planCode 
        && order.isDisplayedOnMySubs; 
    });
    return foundOrder ? true : false;
  });
  // return submittedOrders;
  return submittedOrders.filter((order, index, self) => {
    return  self.findIndex(t =>  t.prodIdAlias === order.prodIdAlias 
      && t.planCode === order.planCode 
      && t.accountNumber === order.accountNumber) === index; // dedupe);;
  });
  
};

export const getResumedOrders = createSelector([resumedOrders], order => order);

export const getCustomers = createSelector(getAccount, (account: any) => {
  return account.customerData.customers;
});

export const getClientNamesForCustomers = createSelector(
  getAccount,
  getClientConfig,
  getAdminConfig,
  (account: AccountState, clientConfig: ConfigState, adminConfig: AdminConfigState ) => {
    if (account.customerData.customers) {
      let clientNames: Array<{ prodIdAlias: string; clientName: string }> = [];
      account.customerData.customers.forEach(customer => {
        if (clientNames.filter(item => item.prodIdAlias === customer.prodIdAlias).length === 0) {
          let clientProperties = getAdminConfigTableItem(adminConfig, clientConfig, 'ClientProperties', customer.prodIdAlias);
          let name = customer.systemCode === 'SERVPF'  ? clientProperties.ClientName : clientProperties.ProductName;
          const found = clientNames.some(el => el.clientName === name);
          if (!found) {
            clientNames.push({
              prodIdAlias: customer.prodIdAlias,
              clientName: customer.systemCode === 'SERVPF'  ? clientProperties.ClientName : clientProperties.ProductName,
            });
          }
        }
      });
      return clientNames;
    }
    return null;
  },
);

export const getProdIdsForCustomers = createSelector(
  getAccount,
  (account: AccountState) => {
    if (account.orderData.orders) {
      let prodNames: Array<{ label: string; value: string }> = [];
      account.orderData.orders.forEach(order => {
        let productDesc = '';
        if (!order.isContinuity && order.isPf && account.customerData.customers) {
          let cust = account.customerData.customers.find(customer => {
            return customer.prodIdAlias.substring(0, 3) === order.prodIdAlias.substring(0, 3); 
          });
          productDesc = cust ? cust.productName : order.productName;
        } else {
          productDesc = order.isContinuity ? order.subscriptionName : order.productName; 
        }
    
        if (prodNames.filter(item => item.label === productDesc).length === 0) {
          prodNames.push({
            label: productDesc,
            value: order.prodIdAlias,
          });
        }
      });
      return prodNames;
    }
    return null;
  },
);
export const getProdIdsFromAdminConfig = createSelector(
  getAdminConfig,
  (adminConfig: AdminConfigState) => {
    if (adminConfig.adminConfig) {
      let prodNames: Array<{ label: string; value: string }> = [];
      Object.keys(adminConfig.adminConfig).forEach(function(key: string, index: any) {
        if (adminConfig.adminConfig[key].ClientProperties.ProductName) {
          prodNames.push({
            label: adminConfig.adminConfig[key].ClientProperties.ProductName,
            value: adminConfig.adminConfig[key].ClientProperties.ProdId,
          });
        }
      }, adminConfig.adminConfig);
      return prodNames;
    }
    return null;
  },
);
export const getProdIdsForGlobalCodeFromAdminConfig = createSelector(
  getAdminConfig,
  getClientConfig,
  (adminConfig: AdminConfigState, clientConfig: ConfigState) => {
    let instanceMag = clientConfig.clientConfig.magCode;
    let clientPropsTableName = 'ClientProperties';
    let availActionsTableName = 'AvailableActions';

    let productExcludes: Array<string> = [];
    let products: Array<string> = [];
    if (adminConfig.adminConfig[instanceMag]) {
      let globalCode = adminConfig.adminConfig[instanceMag][clientPropsTableName].GlobalCode;
      Object.keys(adminConfig.adminConfig).forEach(key => {
        if (key !== '*' && adminConfig.adminConfig[key].ClientProperties.GlobalCode === globalCode) {
          products.push(key);
        }
      }, adminConfig.adminConfig);
      
      products.forEach(product => {
        if (adminConfig.adminConfig[product][availActionsTableName] 
          && adminConfig.adminConfig[product][availActionsTableName].Preferences
          && adminConfig.adminConfig[product][availActionsTableName].Preferences.excludeProductOnGCF) {
          productExcludes.push(product);
        }
      });
      return productExcludes;
    }
    return null;
  },
);
