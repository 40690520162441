import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { WeakLogin } from '@src/app/login/components';
import { getSocialEmail, getMylo } from '@src/app/login/selectors';
import { connect } from 'react-redux';
import { RootState } from '@src/app/_redux';
import { LoginTabs } from '@src/app/login/types';
import { AuthState } from '@src/app/login/reducers';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PageIntro from '@src/app/accounts/summary/components/PageIntro';
import { getAdminConfigTableItem } from '@src/app/_global/utility/adminConfigUtils';
import '../login.scss';
import { Cognito } from '@src/app/login/components/Cognito';
import {
  actionCreators as cognitoActions,
  cognitoLogout,
} from '@src/app/login/cognito/actions';
import Mylo from 'mylo-js';

export interface LoginProps {
  clientConfig: any;
  adminConfig: any;
  auth: AuthState;
  socialEmail: any;
  myloLogin: boolean;
  myloConfig: any;
  signinAttempts: number;
  mylo: Mylo;
  ageCheckPassed: boolean;
}

const ErrorView: React.SFC<any> = props => {
  let signInData = getAdminConfigTableItem(
    props.adminConfig,
    props.clientConfig,
    'SignIn',
  );
  let socialLoginDesc = 'social';
  if (props.socialLogin.user) {
    switch (props.socialLogin.user.channel) {
      // First two cases are dead code - the COGNITO_SUCCESS
      // reducer will always set this to 'cognito'.
      case 'google':
        socialLoginDesc = 'Google';
        break;
      case 'facebook':
        socialLoginDesc = 'Facebook';
        break;
      default:
        socialLoginDesc = 'social';
    }
  }
  const loginInstructionsHeaderDefault = 'Great! Just one more step.';
  const loginInstructionsTextDefault = `<p>Please use one of the options below to link your account.<br />You'll only have to do this once.</p>`;

  return (
    <div className="purgatory-view">
      {signInData.ResinLinkHeader ? 
      (
        <FormattedHTMLMessage
          id="login.notfound"
          description="Message showing successful social but account not found"
          defaultMessage={signInData.ResinLinkHeader}
        />
      )
      :
      (
        <h3 className="">
        <FormattedMessage
          id="login.notfound"
          description="Message showing successful social but account not found"
          defaultMessage={loginInstructionsHeaderDefault}
        />
      </h3>
      )}
      <FormattedHTMLMessage
        id="login.notfoundMessage"
        description="Instructions to finish login"
        defaultMessage={signInData.ResinLinkText ? signInData.ResinLinkText : loginInstructionsTextDefault}
      />

      <div>
        <a href="#"
          onClick={() => cognitoLogout(props.myloLogin, props.myloConfig)}
          className=""
        >
          <FormattedMessage
            id="app.cancel"
            description="Cancel"
            defaultMessage="Return to Sign In"
          />
        </a>
      </div>
    </div>
  );
};

export class LoginForm extends React.Component<LoginProps> {
  constructor(props: any) {
    super(props);
  }
  // 'Choose one of these to sign in.'
  // 'Or choose one of these to sign in.'
  render() {
    let signInData = getAdminConfigTableItem(
      this.props.adminConfig,
      this.props.clientConfig,
      'SignIn',
    );
    const { socialEmail, auth, myloLogin, myloConfig} = this.props;
    const isPurgatoryView = this.props.auth.omsAuth.isFailure && socialEmail;
    const chooseVerbiage = isPurgatoryView
      ? 'login.subheadingone'
      : 'login.subheadingtwo';
    const liveChatData = getAdminConfigTableItem(
      this.props.adminConfig,
      this.props.clientConfig,
      'LiveChat',
    );
    const viewChat = () => {
      let chatWidth = liveChatData.ChatWindowWidth ? liveChatData.ChatWindowWidth : 250;
      let chatHeight = liveChatData.ChatWindowHeight ? liveChatData.ChatWindowHeight : 250;
      window.open(liveChatData.ChatUrl, 'LiveChat', 'width=' + chatWidth + ',height=' + chatHeight);
    };
    const handleKeyPress = e => {
      let x = e.which || e.keyCode;
      // tslint:disable-next-line:no-unused-expression
      (x === 13 || x === 32) && viewChat();
    };
    let enabledSoftLogins: Array<string> = [];
    if (signInData.AccountNumberPostalCodeEnabled) {
      enabledSoftLogins.push(LoginTabs.accountPostalCode);
    }
    if (signInData.EmailPostalCodeEnabled) {
      enabledSoftLogins.push(LoginTabs.emailPostalCode);
    }
    if (signInData.NameAddressEnabled) {
      enabledSoftLogins.push(LoginTabs.nameAndAddress);
    }
    function createMarkup(nodeItem: any) {
      return {__html: nodeItem };
    }

    return (
      <React.Fragment>
        <div> 
                {liveChatData.ChatEnabled &&
          liveChatData.EnabledRoutes.filter(item => item.title === 'Sign In')
            .length !== 0 && (
            <div className="livechat-card">
              <a
                className="nav-link"
                tabIndex={0}
                role="button"
                onKeyPress={e => handleKeyPress(e)}
                onClick={() => viewChat()}
              >
                <span className="nav-text">Live Chat</span>
                <i className="ml-1 mr-1 fa fa-comments-o" />
              </a>
            </div>
          )}
          </div>
        <div className="login-view text-center">

          <Container id="main" className="main-long">
            {!isPurgatoryView && (
              <Row className="mb-4 justify-content-center">
                <Col sm={12} md={12} lg={8}>
                  {' '}
                  <PageIntro {...this.props} />
                </Col>
              </Row>
            )}
            <Row className="justify-content-center">
              {(signInData.EmailPasswordEnabled)   && (
                <Col sm={12} md={12} lg={8}>
                  {isPurgatoryView && (
                    <ErrorView
                      resetlookup={cognitoLogout}
                      email={socialEmail}
                      socialLogin={auth.socialLogin}
                      myloLogin={myloLogin}
                      myloConfig={myloConfig}
                      clientConfig={this.props.clientConfig}
                      adminConfig={this.props.adminConfig}
                    />
                  )}
                  {!isPurgatoryView && (
                    <Cognito
                      authData={
                        this.props.clientConfig.clientConfig.ciamAuthData
                      }
                      signInData={signInData}
                      onSuccess={cognitoActions.cognitoGetIdentityId}
                      mylo={this.props.mylo}
                    />
                  )}
                </Col>
              )}
            </Row>
            {(signInData.EmailPostalCodeEnabled || signInData.AccountNumberPostalCodeEnabled || signInData.NameAddressEnabled ) && (
            <Row className="social-signin justify-content-center">
              <Col sm={12} md={12} lg={8}>
              {!isPurgatoryView && signInData.EmailPasswordEnabled && !signInData.MyloOnlyEnabled && ( 
                <h4 id="LoginViewSocial">
                  <FormattedMessage
                    id={chooseVerbiage}
                    description="Change heading for pergatory view"
                    defaultMessage={
                      (!signInData.EmailPasswordEnabled ? 'Please sign in below:' :  'or sign in as a guest:')
                    }
                  />
                </h4>
              )}
                {this.props.clientConfig.configLoading && (
                  <div className="loading-container">
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                  </div>
                )}
                {this.props.clientConfig && (isPurgatoryView || (!isPurgatoryView && !signInData.MyloOnlyEnabled)) && (
                  <WeakLogin
                    auth={auth}
                    signInData={signInData}
                    openTab={isPurgatoryView ? LoginTabs.emailPostalCode : null}
                    isPurgatory={isPurgatoryView ? true : false}
                  />
                )}
              </Col>
            </Row>
          )}
          {(signInData.ContactCustomerServiceEnabled) && (signInData.ContactCustomerServiceAttempts) && 
            (signInData.ContactCustomerServiceAttempts <= this.props.signinAttempts) && (
            <div className="pb-3 contact-customer-service">
              <span dangerouslySetInnerHTML={createMarkup(signInData.ContactCustomerServiceText )}></span>
            </div>
          )}
          </Container>

        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    clientConfig: state.clientConfig,
    adminConfig: state.adminConfig,
    auth: state.auth,
    socialEmail: getSocialEmail(state),
    myloLogin: state.auth.omsAuth.credentials && state.auth.omsAuth.credentials.Logins,
    myloConfig: state.clientConfig.clientConfig.myloConfig,
    signinAttempts: state.userState.signinAttempts,
    mylo: getMylo(state),
    ageCheckPassed: state.userState.ageCheckPassed
  };
};

export const Login = connect(mapStateToProps)(LoginForm);
