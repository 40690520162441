export const UPDATE_PAYMENT_START = 'UPDATE_PAYMENT_START';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';
export const UPDATE_PAYMENT_LOADING = 'UPDATE_PAYMENT_LOADING';
export const UPDATE_PAYMENT_CANCEL = 'UPDATE_PAYMENT_CANCEL';

export type Actions = {
  UPDATE_PAYMENT_START: { type: typeof UPDATE_PAYMENT_START; payload: boolean };
  UPDATE_PAYMENT_LOADING: { type: typeof UPDATE_PAYMENT_LOADING; payload: boolean };
  UPDATE_PAYMENT_SUCCESS: { type: typeof UPDATE_PAYMENT_SUCCESS; payload: any };
  UPDATE_PAYMENT_ERROR: { type: typeof UPDATE_PAYMENT_ERROR; payload: any };
  UPDATE_PAYMENT_CANCEL: { type: typeof UPDATE_PAYMENT_CANCEL; payload: any };
};
// Action Creators
export const actionCreators = {
  updatePaymentStart: (payload: any): Actions[typeof UPDATE_PAYMENT_START] => ({
    type: UPDATE_PAYMENT_START,
    payload,
  }),
  updatePaymentError: (payload: any): Actions[typeof UPDATE_PAYMENT_ERROR] => ({
    type: UPDATE_PAYMENT_ERROR,
    payload,
  }),
  updatePaymentSuccess: (payload: any): Actions[typeof UPDATE_PAYMENT_SUCCESS] => {
    return ({ type: UPDATE_PAYMENT_SUCCESS, payload: payload});
  }
  ,
  updatePaymentLoading: (payload: boolean): Actions[typeof UPDATE_PAYMENT_LOADING] => ({
    type: UPDATE_PAYMENT_LOADING,
    payload,
  }),
  updatePaymentCancel: (payload: boolean): Actions[typeof UPDATE_PAYMENT_CANCEL] => ({
    type: UPDATE_PAYMENT_CANCEL,
    payload,
  }),
};
