import { COGNITO_SUCCESS, COGNITO_REFRESH, COGNITO_GET_IDENTITY_ID } from '@src/app/login/cognito/actions';
import { actionCreators } from '@src/app/login/oms/actions';
import { RootState, RootAction } from '@src/app/_redux';
import { ActionsObservable, StateObservable, ofType, combineEpics } from 'redux-observable';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { actionCreators as omsActionCreator } from '@src/app/login/oms/actions';
import { actionCreators as cognitoActionCreator } from '@src/app/login/cognito/actions';
import { actionCreators as userActionCreator } from '@src/app/user/actions';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { getProdIdsForGlobalCodeFromAdminConfig } from '@src/app/accounts/selectors';
import { CognitoIdentityClient, GetIdCommand } from '@aws-sdk/client-cognito-identity';

const cognitoLoginEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(COGNITO_SUCCESS),
    switchMap(({ payload }) => {
      const loading = of(actionCreators.setAuthenticating(true));
      const idToken = payload.idToken;
      const accessToken = payload.accessToken;

      let tokenParts = idToken.jwtToken.split('.');
      let tokenPayload = tokenParts[1];
      const decodedIdToken = atob(tokenPayload);

      const tokenObject = JSON.parse(decodedIdToken);
      const idProvider = tokenObject.iss.split('//')[1];

      // The authenicated login process now routes thru the COGNITO_GET_IDENTITY_ID
      // action which will add the IdentityId to the payload.
      const credentialsObject = {
        IdentityId: payload.identityId,
        Logins: {
          [idProvider]: idToken.jwtToken,
        },
      };

      const idpAccountInfo = {
        sub: tokenObject.sub,
        email: tokenObject.email,
        username: tokenObject.username,
      };
      const saveIdpAccountInfo = of(userActionCreator.idpAccountInfo({payload: idpAccountInfo}));

      const setCreds = of(omsActionCreator.omsSetCredentials(credentialsObject));

      const clientConfig = state$.value.clientConfig.clientConfig;
      
      // get the login values from state, these should only be set when using
      // the mylo/continue route
      const loginAccountNumber = state$.value.userState.loginAccountNumber;
      const loginPostalCode = state$.value.userState.loginPostalCode;

      const apiConfig: ApiConfig = {
        credentialsObject: credentialsObject,
        url: clientConfig.url,
        path: '/mylo',
        region: clientConfig.Region,
        requestmethod: 'POST',
        requestbody: {
          myloIdentitySub: tokenObject.sub, 
          globalCode: clientConfig.globalCode, 
          accessToken: accessToken.jwtToken,
          myloLegacySub: tokenObject.legacy_sub,
        },
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      const client = new ApiClient();
      var observableStream = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {

          gaActionCreators.createGAEvent({
            category: 'Login with Resin ID',
            action: 'Successful',
            label: state$.value.clientConfig.clientConfig.magCode,
          });
    
          const accountNumber = loginAccountNumber || answer.accountId;
          const postalCode = loginPostalCode || answer.postalCode;
          if (accountNumber && postalCode) {
            const productExcludes = getProdIdsForGlobalCodeFromAdminConfig(state$.value);
            return actionCreators.initiateOmsLookup(accountNumber, postalCode, idToken.jwtToken, idProvider, productExcludes, idpAccountInfo);
          } else {
            const response = {isSuccess: false, prodId: '', accountsMap: {}, customers: [], orders: [], associatedGiftAccounts: {}};
            return actionCreators.omsLookupFailure(response);
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: 'Login with Resin ID',
            action: 'Unsuccessful',
            label: state$.value.clientConfig.clientConfig.magCode,
          });
          const response = {isSuccess: false, prodId: '', accountsMap: {}, customers: [], orders: [], associatedGiftAccounts: {}};
          return of(actionCreators.omsLookupFailure(response));
        }),
      );
      return concat(loading, setCreds, saveIdpAccountInfo, observableStream);
    }),
  );

  const cognitoGetIdentityId = (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
    action$.pipe(
      ofType(COGNITO_GET_IDENTITY_ID),
      switchMap(({ payload }) => {
        const loading = of(actionCreators.setAuthenticating(true));

        const idToken = payload.idToken;
        let tokenParts = idToken.jwtToken.split('.');
        let tokenPayload = tokenParts[1];
        const decodedIdToken = atob(tokenPayload);
        const tokenObject = JSON.parse(decodedIdToken);
        const idProvider = tokenObject.iss.split('//')[1];

        const clientConfig = state$.value.clientConfig.clientConfig;

        const credentialsObject = {
          IdentityPoolId: clientConfig.identityPoolId,
          Logins: {
            [idProvider]: idToken.jwtToken,
          },
        };
        const client = new CognitoIdentityClient({region: clientConfig.Region});
        const command = new GetIdCommand(credentialsObject);

        var observableStream = fromPromise(client.send(command)).pipe(
          map(answer => {
              payload.identityId = answer.IdentityId;
              return cognitoActionCreator.cognitoSuccess(payload);
          }),
          catchError(err => {
            gaActionCreators.createGAEvent({
              category: 'Login with Resin ID',
              action: 'Unsuccessful',
              label: state$.value.clientConfig.clientConfig.magCode,
            });
            const response = {isSuccess: false, prodId: '', accountsMap: {}, customers: [], orders: [], associatedGiftAccounts: {}};
            return of(actionCreators.omsLookupFailure(response));
          }),
        );
        return concat(loading, observableStream);
      }),
    );
  
  const cognitoRefreshCredentials = (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
    action$.pipe(
      ofType(COGNITO_REFRESH),
      switchMap( ({ payload }) => {
        gaActionCreators.createGAEvent({
          category: 'Continue Session',
          action: 'Successful',
          label: state$.value.clientConfig.clientConfig.magCode,
        });
  
        const loading = of(actionCreators.setAuthenticating(true));
        var observableStream = concat(
          loading,
          of(cognitoActionCreator.cognitoRefreshSuccess({history: payload.history})),
        );
        return observableStream;
      }),
    );
export const epics = combineEpics(cognitoLoginEpic, cognitoGetIdentityId, cognitoRefreshCredentials);
