import {
  ActionsObservable,
  StateObservable,
  ofType,
  combineEpics,
} from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { IntelliselGetOfferPayload, IntelliselOfferResponsePayload } from '@src/_api/payload/intellisel';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';

import {
  INTELLISEL_START,
  actionCreators,
} from './actions';

import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concat } from 'rxjs/observable/concat';
import { actionCreators as omsActionCreators } from '@src/app/login/oms/actions';
import { actionCreators as authActions } from '@src/app/header/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';

const intelliselEvents = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(INTELLISEL_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.intelliselLoading(true));
      const client = new ApiClient();
      let postBody: IntelliselGetOfferPayload | IntelliselOfferResponsePayload = payload;
      
      const apiConfig: ApiConfig = {
        credentialsObject: state$.value.auth.omsAuth.credentials,
        url: clientConfig.url,
        path: '/intellisel',
        region: clientConfig.Region,
        requestmethod: 'POST',
        requestbody: postBody,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      const source$ = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.success) === 'true';
          if (isSuccess) {
            if (payload.intellisel.event === 'offerresponse') {
              if (payload.offerValue) {
                gaActionCreators.createGAEvent({
                  category: payload.googleAnalyticsCategory,
                  action: 'Successful',
                  value: parseFloat(payload.offerValue) * 100,
                  label: payload.prodIdAlias,
                });
              } else {
                gaActionCreators.createGAEvent({
                  category: payload.googleAnalyticsCategory,
                  action: 'Successful',
                  label: payload.prodIdAlias,
                });
              }
            }
            return actionCreators.intelliselSuccess({
              success: true,
              transactionCode: payload.intellisel.transactionCode,
              response: answer.intellisels[0].offers,
            });
          } else {
            if (payload.intellisel.event === 'offerresponse') {
              if (payload.offerValue) {
                gaActionCreators.createGAEvent({
                  category: payload.googleAnalyticsCategory,
                  action: 'Unsuccessful',
                  value: parseFloat(payload.offerValue) * 100,
                  label: payload.prodIdAlias,
                });
              } else {
                gaActionCreators.createGAEvent({
                  category: payload.googleAnalyticsCategory,
                  action: 'Unsuccessful',
                  label: payload.prodIdAlias,
                });
              }
            }
            return actionCreators.intelliselError({
              success: false,
              message: 'Error on Load of Intellisel',
            });
          }
        }),
        catchError(err => {
        // if error msg contains "Invalid login token" then fire logout action with different notifcation.
        if (err && err.message.includes('Invalid login token')) {
          gaActionCreators.createGAEvent({
            category: payload.googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload.prodIdAlias,
          });
          omsActionCreators.omsLookupFailureNotification(
            err ? err.message : 'Session Timeout',
          );
          return of(authActions.userLogout()); 
        } else {
          if (payload.intellisel.event === 'offerresponse') {
            gaActionCreators.createGAEvent({
              category: payload.googleAnalyticsCategory,
              action: 'Unknown Error',
              label: payload.prodIdAlias,
            });
          }
          omsActionCreators.omsLookupFailureNotification(
            err ? err.message : 'Unknown error',
          );
          return of(
            actionCreators.intelliselError({
              success: false,
              message: err,
            }),
          );
        }

        }),
      );

      return concat(loading, source$);
    }),
  );

export const epics = combineEpics(intelliselEvents);
