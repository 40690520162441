import { RootAction, RootState } from '@src/app/_redux';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  UNAUTH_IAM_KEYS_SUCCESS,
  UNAUTH_GET_IAM_KEYS,
  REFETCH_CUSTOMER_DATA,
  actionCreators as weakActions,
  AUTH_IAM_KEYS_SUCCESS,
  FIND_SECURED_USER,
} from '@src/app/login/weak/actions';
import { switchMap, filter, map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concat } from 'rxjs/observable/concat';
import { actionCreators } from '@src/app/login/oms/actions';
import { actionCreators as accountActions } from '@src/app/accounts/summary/actions';
import { actionCreators as accountSummaryActionCreators } from '@src/app/accounts/summary/actions';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain/apiGateway';
import { errorMessageMap } from '@src/app/login/errors';
import { CustomResponse } from '@CDSGlobal/gc-backend';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { from } from 'rxjs/internal/observable/from';
import { actionCreators as authActions } from '@src/app/header/actions';
import { getAdminConfigTableItem } from '@src/app/_global/utility/adminConfigUtils';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { CognitoIdentityClient, GetIdCommand } from '@aws-sdk/client-cognito-identity';

const GC_CUSTOMER_PATH = '/GC-call-globalcustomer';
export const getUnauthIdentityId = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(UNAUTH_GET_IAM_KEYS),
    mergeMap(async ({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const client = new CognitoIdentityClient({region: clientConfig.Region});
      let params = {
        IdentityPoolId: clientConfig.identityPoolId,
      };
      let theId = 'initialid';

      const command = new GetIdCommand(params);
      try {
        const data = await client.send(command);
        theId = data.IdentityId || '';
        return weakActions.unauthIamKeysSuccess(theId, payload);      
      } catch (error) {
        return weakActions.unauthIamKeysSuccess('', {
          filterType: payload.filterType,
          globalCode: clientConfig.globalCode,
          magCode: payload.magCode,
        });
      }
    }),
  );

export const doUnauthLookup = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(UNAUTH_IAM_KEYS_SUCCESS),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.setAuthenticating(true));

      const credentialsObject = {
        IdentityId: payload.identityId,
      };

      let lookupType;
      if (payload.wsgPayload.payload.acctNbr) {
        lookupType = 'Login by Account Number and Postal Code';
      } else if (payload.wsgPayload.payload.name) {
        lookupType = 'Login by Name and Address';
      } else {
        lookupType = 'Login by Email and Postal Code';
      }

      const client = new ApiClient();
      const apiConfig: ApiConfig = {
        credentialsObject: credentialsObject,
        url: clientConfig.url,
        path: GC_CUSTOMER_PATH,
        region: clientConfig.Region,
        requestmethod: 'POST',
        requestbody: payload.wsgPayload,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      sessionStorage.setItem('loginData', JSON.stringify(payload.wsgPayload));

      const source$ = from(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.isSuccess) === 'true';
          if (isSuccess) {
            let signInData = getAdminConfigTableItem(
              state$.value.adminConfig,
              state$.value.clientConfig,
              'SignIn',
            );
            if (signInData.RequireSecureSignin && answer.customers[0].recRegId) {
              return weakActionCreators.findMyloUser(<CustomResponse> answer, credentialsObject, lookupType);
            } else {
              gaActionCreators.createGAEvent({
                category: lookupType,
                action: 'Successful',
                label: answer.customers[0].prodIdAlias
              });
              return actionCreators.omsLookupSuccess(<CustomResponse> answer);
            }
          } else {
            gaActionCreators.createGAEvent({
              category: lookupType,
              action: 'Unsuccessful',
              label: clientConfig.magCode,
            });
            var msg;

            try {
              msg = errorMessageMap.get(
                answer.globalcustomer['0'].recReg.errors['0'].code,
              );
            } catch (error) {
              msg = 'Account lookup failure: Please retry.';
            }
            actionCreators.omsLookupFailureNotification(msg);
            return actionCreators.omsLookupFailure(['Lookup Failure']);
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: lookupType,
            action: 'Unknown Error',
            label: clientConfig.magCode,
          });
          /// if error msg contains "Invalid login token" then fire logout action with different notifcation.
          if (err && err.message.includes('Invalid login token')) {
            actionCreators.omsLookupFailureNotification(
              err ? err.message : 'Session Timeout',
            );
            return of(authActions.userLogout()); 
          } else {
            actionCreators.omsLookupFailureNotification(
              err ? err.message : 'Unknown error',
            );
            return of(actionCreators.omsLookupFailure(err));
          }
        }),
      );
      if (payload.wsgPayload.payload.email.length > 0 && payload.wsgPayload.payload.emailOptIn) {
        return concat(
         loading,
         source$,
          of(actionCreators.omsSetCredentials(credentialsObject)),
          of(accountSummaryActionCreators.autoCofeSaveStart(
            { email: payload.wsgPayload.payload.email, 
              emailOptIn: payload.wsgPayload.payload.emailOptIn ? 'Y' : ' ',
              prodIdAlias: payload.wsgPayload.magCode,
              googleAnalyticsCategory: 'Add Email From Sign In',
            } 
            )),
       );
     } else {
       return concat(
         loading,
         source$,
         of(actionCreators.omsSetCredentials(credentialsObject)),
       );
     }
    }),
  );

export const doAuthLookup = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(AUTH_IAM_KEYS_SUCCESS),
    filter(action => action.payload !== ''),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.setAuthenticating(true));
      const credentialsObject = payload.credentials;

      let lookupType;
      if (payload.wsgPayload.payload.acctNbr) {
        lookupType = 'Login by Account Number and Postal Code';
      } else if (payload.wsgPayload.payload.name) {
        lookupType = 'Login by Name and Address';
      } else {
        lookupType = 'Login by Email and Postal Code';
      }

      const client = new ApiClient();
      const apiConfig: ApiConfig = {
        credentialsObject: credentialsObject,
        url: clientConfig.url,
        path: GC_CUSTOMER_PATH,
        region: clientConfig.Region,
        requestmethod: 'POST',
        requestbody: payload.wsgPayload,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      sessionStorage.setItem('loginData', JSON.stringify(payload.wsgPayload));

      const source$ = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.isSuccess) === 'true';
          if (isSuccess) {
            gaActionCreators.createGAEvent({
              category: lookupType,
              action: 'Successful',
              label: answer.customers[0].prodIdAlias
            });
            return actionCreators.omsLookupSuccess(<CustomResponse> answer);
          } else {
            gaActionCreators.createGAEvent({
              category: lookupType,
              action: 'Unsuccessful',
              label: clientConfig.magCode,
            });
            var msg;

            try {
              msg = errorMessageMap.get(
                answer.globalcustomer['0'].recReg.errors['0'].code,
              );
            } catch (error) {
              msg = 'Account lookup failure: Please retry.';
            }
            actionCreators.omsLookupFailureNotification(msg);
            return actionCreators.omsLookupFailure(['Lookup Failure']);
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: lookupType,
            action: 'Unknown Error',
            label: clientConfig.magCode,
          });
          /// if error msg contains "Invalid login token" then fire logout action with different notifcation.
          if (err && err.message.includes('Invalid login token')) {
            actionCreators.omsLookupFailureNotification(
              err ? err.message : 'Session Timeout',
            );
            return of(authActions.userLogout()); 
          } else {
            actionCreators.omsLookupFailureNotification(
              err ? err.message : 'Unknown error',
            );
            return of(actionCreators.omsLookupFailure(err));
          }
        }),
      );

      if (payload.wsgPayload.payload.email.length > 0 && payload.wsgPayload.payload.emailOptIn) {
         return concat(
          loading,
          source$,
           of(actionCreators.omsSetCredentials(credentialsObject)),
           of(accountSummaryActionCreators.autoCofeSaveStart(
             {
               email: payload.wsgPayload.payload.email, 
               emailOptIn: payload.wsgPayload.payload.emailOptIn ? 'Y' : ' ',
               prodIdAlias: payload.wsgPayload.magCode,
               googleAnalyticsCategory: 'Add Email From Sign In',
              } 
            )),
        );
      } else {
        return concat(
          loading,
          source$,
          of(actionCreators.omsSetCredentials(credentialsObject)),
        );
      }
    }),
  );

export const fetchCustomer = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(REFETCH_CUSTOMER_DATA),
    mergeMap(() => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(accountActions.accountSaveLoading(true));
      const payload = JSON.parse(sessionStorage.getItem('loginData') as string);

      const apiConfig: ApiConfig = {
        credentialsObject: state$.value.auth.omsAuth.credentials,
        url: clientConfig.url,
        path: GC_CUSTOMER_PATH,
        region: clientConfig.Region,
        requestmethod: 'POST',
        requestbody: payload,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      const client = new ApiClient();
      const $source = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.isSuccess) === 'true';
          if (isSuccess) {
            return actionCreators.omsLookupSuccess(<CustomResponse> answer);
          } else {
            return actionCreators.omsLookupFailure([answer]);
          }
        }),
        catchError(err => {
          /// if error msg contains "Invalid login token" then fire logout action with different notifcation.
          if (err && err.message.includes('Invalid login token')) {
            actionCreators.omsLookupFailureNotification(
              err ? err.message : 'Session Timeout',
            );
            return of(authActions.userLogout()); 
           } else {
            actionCreators.omsLookupFailureNotification(
              err ? err.message : 'Unknown error',
            );
            return of(actionCreators.omsLookupFailure(err));
          }
        }),
      );

      return concat(loading, $source);
    }),
  );

  export const findSecuredUserEpic = (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
    action$.pipe(
      ofType(FIND_SECURED_USER),
      switchMap(({ payload }) => {
        const clientConfig = state$.value.clientConfig.clientConfig;
        const loading = of(actionCreators.setAuthenticating(true));
        const regRegId = payload.payload.customers[0].recRegId;
        const credentialsObject = payload.credentials;
        const setCreds = of(actionCreators.omsSetCredentials(credentialsObject));
  
        const apiConfig: ApiConfig = {
          credentialsObject: credentialsObject,
          url: clientConfig.url,
          path: '/mylo',
          region: clientConfig.Region,
          requestmethod: 'POST',
          requestbody: {
            globalCode: clientConfig.globalCode, // recregid is unique across global codes
            recRegId: regRegId
          },
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
  
        const client = new ApiClient();
        var observableStream = fromPromise(client.callApiGateway(apiConfig)).pipe(
          map(answer => {
            // in new epic if recregid lookup succeeds, redirect to login w/msg and disable soft login (change userstate)
            // in new epic if recregid lookup fails then call omsLookupSuccess
            if (answer[0] && answer[0].RecRegId) {  
              gaActionCreators.createGAEvent({
                category: 'Guest User - Requires Secure Sign In',
                action: 'Unsuccessful',
                label: clientConfig.magCode,
              });
             actionCreators.omsLookupFailureNotification('Guest sign in is unavailable because you previously secured your account. Use the “Sign In” option to access your account.');
             const response = {isSuccess: false, prodId: '', accountsMap: {}, customers: [], orders: [], associatedGiftAccounts: {}};
             return actionCreators.omsLookupFailure(response);
            } else {
              gaActionCreators.createGAEvent({
                category: payload.lookupType,
                action: 'Successful',
                label: payload.payload.customers[0].prodIdAlias
              });
              return actionCreators.omsLookupSuccess(<CustomResponse> payload.payload);
            }

          }),
          catchError(err => {
            const response = {isSuccess: false, prodId: '', accountsMap: {}, customers: [], orders: [], associatedGiftAccounts: {}};
            return of(actionCreators.omsLookupFailure(response));
          }),
        );
        return concat(loading, setCreds, observableStream);
      }),
    );
  