import { RootAction } from '@src/app/_redux';
import { combineReducers } from 'redux';
import { 
  VIEW_ALL_ORDERS, 
  VIEW_ALL_GIFTS, 
  VIEW_ALL_UNREDEEMED_GIFTS,
  VIEW_ALL_SUBS, 
  LEFT_NAV_OPEN,
  AGE_CHECK_PASSED,
  LOGIN_ACCOUNT_NUMBER,
  LOGIN_POSTAL_CODE,
  IDP_ACCOUNT_INFO,
  DIRECT_VIEW_LOGIN,
} from './actions';
import { OMS_LOOKUP_FAILURE } from '../login/oms/actions';
import { USER_LOGOUT } from '../header/actions';
import { IdPAccountInfo } from '@CDSGlobal/gc-backend';

export const initialState = {
  viewAllOrders: false,
  viewAllGifts: false,
  viewAllUnredeemedGifts: false,
  viewAllSubs: false,
  signinAttempts: 0,
  leftNavOpen: false,
  ageCheckPassed: false,
  loginAccountNumber: '',
  loginPostalCode: '',
  idpAccountInfo: {
    sub: '',
    email: '',
    username: '',
  },
  directViewLogin: false,
};
export type State = {
  initialState: UserState;
};
export const reducer = combineReducers<UserState, RootAction>({
  viewAllOrders: (state = initialState.viewAllOrders, { type, payload }) => {
    switch (type) {
      case VIEW_ALL_ORDERS:
         return payload.payload;
      default:
        return state;
    }
  },
  viewAllGifts: (state = initialState.viewAllGifts, { type, payload }) => {
    switch (type) {
      case VIEW_ALL_GIFTS:
        return payload.payload;
      default:
        return state;
    }
  },
  viewAllUnredeemedGifts: (state = initialState.viewAllUnredeemedGifts, { type, payload }) => {
    switch (type) {
      case VIEW_ALL_UNREDEEMED_GIFTS:
        return payload.payload;
      default:
        return state;
    }
  },
  viewAllSubs: (state = initialState.viewAllSubs, { type, payload }) => {
    switch (type) {
      case VIEW_ALL_SUBS:
        return payload.payload;
      default:
        return state;
    }
  },
  signinAttempts: (state = initialState.signinAttempts, { type }) => {
    switch (type) {
      case OMS_LOOKUP_FAILURE:
        return state + 1;
      case USER_LOGOUT:
        return 0;
      default:
        return state;
    }
  },
  leftNavOpen: (state = initialState.leftNavOpen, { type, payload }) => {
    switch (type) {
      case LEFT_NAV_OPEN:
        return payload.payload;
      default:
        return state;
    }
  },
  ageCheckPassed: (state = initialState.ageCheckPassed, { type, payload }) => {
    switch (type) {
      case AGE_CHECK_PASSED:
        return payload.payload;
      default:
        return state;
    }
  },
  loginAccountNumber: (state = initialState.loginAccountNumber, { type, payload }) => {
    switch (type) {
      case LOGIN_ACCOUNT_NUMBER:
        return payload.payload;
      default:
        return state;
    }
  },
  loginPostalCode: (state = initialState.loginPostalCode, { type, payload }) => {
    switch (type) {
      case LOGIN_POSTAL_CODE:
        return payload.payload;
      default:
        return state;
    }
  },
  idpAccountInfo: (state = initialState.idpAccountInfo, { type, payload }) => {
    switch (type) {
      case IDP_ACCOUNT_INFO:
        return payload.payload;
      default:
        return state;
    }
  },
  directViewLogin: (state = initialState.directViewLogin, { type, payload }) => {
    switch (type) {
      case DIRECT_VIEW_LOGIN:
        return payload.payload;
      default:
        return state;
    }
  },
});
export interface UserState {
  viewAllOrders: boolean;
  viewAllGifts: boolean;
  viewAllUnredeemedGifts: boolean;
  viewAllSubs: boolean;
  signinAttempts: number;
  leftNavOpen: boolean;
  ageCheckPassed: boolean;
  loginAccountNumber: string;
  loginPostalCode: string;
  idpAccountInfo: IdPAccountInfo;
  directViewLogin: boolean;
}
