import { combineReducers } from 'redux';
import { RootAction } from '@src/app/_redux';
import {
  UPDATE_PAYMENT_LOADING,
  UPDATE_PAYMENT_ERROR,
  UPDATE_PAYMENT_START,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_CANCEL,
} from '@src/app/accounts/updatepayment/actions';
import { OMS_LOOKUP_SUCCESS } from '@src/app/login/oms/actions';
export type State = {
  readonly loading: boolean;
  updatePaymentResult: Object | null;
  
};

export const reducer = combineReducers<State, RootAction>({
  loading: (state = false, action) => {
    switch (action.type) {
      case UPDATE_PAYMENT_ERROR:
      case UPDATE_PAYMENT_START:
      case OMS_LOOKUP_SUCCESS:
        return false;
      case UPDATE_PAYMENT_LOADING:
        return action.payload;
      default:
        return state;
    }
  },

  updatePaymentResult: (state = null, action) => {
    switch (action.type) {
      case UPDATE_PAYMENT_SUCCESS:
      case UPDATE_PAYMENT_ERROR:
        return action.payload;
      case UPDATE_PAYMENT_CANCEL:
      case UPDATE_PAYMENT_START:
        return null;
      default:
        return state;
    }
  },
});
