import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, SUBSCRIPTION_EXTENSION_START, GET_ENTITLED_ISSUES_START, REPLACEMENT_ISSUE_START } from '@src/app/accounts/issues/actions';
import { EntitledIssueReformatResponse } from '@CDSGlobal/gc-backend';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as intelliselActionCreators } from '@src/app/intellisel/actions';

const ENTITLED_ISSUES_PATH = '/entitledissues';
const SUBSCRIPTION_EXTENSION_PATH = '/orderupdate';
const REPLACEMENT_ISSUE_PATH = '/cofp';

const getEntitledIssues =  (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
    action$.pipe(
      ofType(GET_ENTITLED_ISSUES_START),
      mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.getEntitledIssuesLoading(true));

      const apiConfig: ApiConfig = {
        credentialsObject: state$.value.auth.omsAuth.credentials,
        url: clientConfig.url,
        path: ENTITLED_ISSUES_PATH,
        region: clientConfig.Region,
        requestmethod: 'GET',
        requestbody: {},
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
          queryParams: {
            magCode: payload.currentOrder.prodIdAlias,
            accountNumber: payload.currentOrder.orderItems[0].shipToAccountNumber,
            lastIssue: payload.currentOrder.orderItems[0].lastIssue,
          }
        },
      };

      const client = new ApiClient();
      const $source = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.isSuccess) === 'true';
          if (isSuccess) {
            return actionCreators.getEntitledIssuesSuccess(<EntitledIssueReformatResponse> answer);
          } else {
            return actionCreators.getEntitledIssuesError(answer);
          }
        }),
        catchError(err => {

          return of(
            actionCreators.getEntitledIssuesError({
              success: false,
              message: err,
            }),
          );
        }),
        // .catch(err => {
        //   return of(actionCreators.getEntitledIssuesError(err));
        // }),
      );
      return concat(loading, $source);
    }),
  
  );

const subscriptionExtension = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(SUBSCRIPTION_EXTENSION_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.subscriptionExtensionLoading(true));

      let body = {
        'prodIdAlias': payload.subscriptionExtensionPayload.prodIdAlias,
        'accountNumber': payload.subscriptionExtensionPayload.accountNumber,
        'order': {
          'subscriptionEnd': payload.subscriptionExtensionPayload.subscriptionEnd,
          'reasonCode': payload.subscriptionExtensionPayload.reasonCode,
        }
      };
      const apiConfig: ApiConfig = {
        credentialsObject: state$.value.auth.omsAuth.credentials,
        url: clientConfig.url,
        path: SUBSCRIPTION_EXTENSION_PATH,
        region: clientConfig.Region,
        requestmethod: 'PUT',
        requestbody: body,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      const client = new ApiClient();
      const $source = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.success) === 'true';
          if (isSuccess) {
            gaActionCreators.createGAEvent({
              category: payload.subscriptionExtensionPayload.googleAnalyticsCategory,
              action: 'Successful',
              label: payload.subscriptionExtensionPayload.prodIdAlias,
            });

            return actionCreators.subscriptionExtensionSuccess(<EntitledIssueReformatResponse> answer);
          } else {
            gaActionCreators.createGAEvent({
              category: payload.subscriptionExtensionPayload.googleAnalyticsCategory,
              action: 'Unsuccessful',
              label: payload.subscriptionExtensionPayload.prodIdAlias,
            });
            return actionCreators.subscriptionExtensionError(answer);
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: payload.subscriptionExtensionPayload.googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload.subscriptionExtensionPayload.prodIdAlias,
          });
          return of(
            actionCreators.getEntitledIssuesError({
              success: false,
              message: err,
            }),
          );
        }),
      );
      
      const refetchCustomerPayload = {
        accountNumber: payload.subscriptionExtensionPayload.accountNumber,
        postalCode: payload.subscriptionExtensionPayload.postalCode,
      };
      let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      if (payload.subscriptionExtensionPayload.intelliselPayload) {
        let intelliselAcceptOffer = of(intelliselActionCreators.intelliselStart(payload.subscriptionExtensionPayload.intelliselPayload));
        return concat(loading, $source, intelliselAcceptOffer, reloadCust);
      } else {
        return concat(loading, $source, reloadCust);
      }
    }),
  );

  const replacementIssue = (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
    action$.pipe(
      ofType(REPLACEMENT_ISSUE_START),
      mergeMap(({ payload }) => {
        const clientConfig = state$.value.clientConfig.clientConfig;
        const loading = of(actionCreators.replacementIssueLoading(true));

        let body = {
          'prodIdAlias': payload.replacementIssuePayload.prodIdAlias,
          'accountNumber': payload.replacementIssuePayload.accountNumber,
            'numberOfCopies': payload.replacementIssuePayload.numberOfCopies,
            'reasonCode': payload.replacementIssuePayload.reasonCode,
            'requestIssue': payload.replacementIssuePayload.requestIssue,
        };
        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          url: clientConfig.url,
          path: REPLACEMENT_ISSUE_PATH,
          region: clientConfig.Region,
          requestmethod: 'POST',
          requestbody: body,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
            },
        };
        const client = new ApiClient();
        const $source = fromPromise(client.callApiGateway(apiConfig)).pipe(
          map(answer => {
            const isSuccess = String(answer.success) === 'true';
            if (isSuccess) {
              gaActionCreators.createGAEvent({
                category: payload.replacementIssuePayload.googleAnalyticsCategory,
                action: 'Successful',
                label: payload.replacementIssuePayload.prodIdAlias
              });
              return actionCreators.replacementIssueSuccess(<EntitledIssueReformatResponse> answer);
            } else {
              gaActionCreators.createGAEvent({
                category: payload.replacementIssuePayload.googleAnalyticsCategory,
                action: 'Unsuccessful',
                label: payload.replacementIssuePayload.prodIdAlias
              });
              return actionCreators.replacementIssueError([answer]);
            }
          }),
          catchError(err => {
            gaActionCreators.createGAEvent({
              category: payload.replacementIssuePayload.googleAnalyticsCategory,
              action: 'Unknown Error',
              label: payload.replacementIssuePayload.prodIdAlias
            });
            return of(
              actionCreators.getEntitledIssuesError({
                success: false,
                message: err,
              }),
            );
          }),
        );
        const refetchCustomerPayload = {
          accountNumber: payload.replacementIssuePayload.accountNumber,
          postalCode:  payload.replacementIssuePayload.postalCode,
        };
        let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
        return concat(loading, $source, reloadCust);
      }),
    );
  
export const epics = combineEpics(getEntitledIssues, subscriptionExtension, replacementIssue);
