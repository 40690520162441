import { combineEpics, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, ORDER_UPDATE_START, CANCEL_STOP_AUTO_RENEW_START } from '@src/app/accounts/orders/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as intelliselActionCreators } from '@src/app/intellisel/actions';
import { actionCreators as custServActionCreators } from '@src/app/accounts/customer-service/actions';
import { OrderUpdatePayload, OrderUpdatePutbody } from '@src/_api/payload/orderupdate';
import { forkJoin } from 'rxjs';
import { StopAutoRenewBody } from '@src/_api/payload/stopauto-renew';
import { getLoggedInCustomer } from '../selectors';

const ORDER_UPDATE_PATH = '/orderupdate';
const STOP_AUTO_RENEW_PATH = '/cofp';

const getAllSucceeded = (...responses) => {
  const successCount = responses.filter(item => item.success);
  return successCount.length === responses.length;
};

const getStopAutoRenewSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    item.success === true);
  return successResponses;
};
const formatPutBody = (item: OrderUpdatePayload) => {
  let body: OrderUpdatePutbody = {
    prodIdAlias: '',
    accountNumber: '',
    order: {
        orderNumber: '',
        orderStatus: '',
        reasonCode: '',
        giftSet: '',
    }
  };
  if (item.systemCode === 'CORE') {
    body = {
      prodIdAlias: item.prodIdAlias,
      accountNumber: item.accountNumber,
      systemCode: item.systemCode,
      coreTransactionType: item.coreTransactionType,
      order: {
        entityId: item.entityId,
        entityType: item.entityType,
        reasonCode: item.reasonCode,
        termNumber: item.termNumber
      },
    };
  } else {
    body = {
      prodIdAlias: item.prodIdAlias,
      accountNumber: item.accountNumber,
      order: {
        orderNumber: item.orderNumber,
        invoiceNumber: item.invoiceNumber,
        orderStatus: item.orderStatus,
        reasonCode: item.reasonCode,
        giftSet: item.giftSet,
        orderItems: item.orderItems && item.orderItems.length > 0 ? item.orderItems : undefined,
        cancel: item.orderStatus === 'cancel' ? 'Y' : '',
        migration: item.migration,
        toMagAbbr: item.toMagAbbr,
        toDocKey: item.toDocKey,
        moveSw: item.moveSw,
        toService: item.toService,
        newTerm: item.newTerm,
        newValue: item.newValue,
        changeOffer: item.changeOffer,
        refundType: item.refundType,
      },
    };
  }
  return body;
};
const orderUpdate = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(ORDER_UPDATE_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.orderUpdateLoading(true));

      const client = new ApiClient();
      const promises = payload.map(item => {
        let body = formatPutBody(item);
        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          url: clientConfig.url,
          path: ORDER_UPDATE_PATH,
          region: clientConfig.Region,
          requestmethod: 'PUT',
          requestbody: body,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });

      const source$ = forkJoin(promises, getAllSucceeded).pipe(
        map(allSucceeded => {
          let gaCategory = payload[0].googleAnalyticsCategory === 'Subscription Transfer'
            ? payload[0].googleAnalyticsCategory + ' to ' + payload[0].toMagAbbr : payload[0].googleAnalyticsCategory;
          if (allSucceeded) {
            gaActionCreators.createGAEvent({
              category: gaCategory,
              action: 'Successful',
              label: payload[0].prodIdAlias
            });
            return actionCreators.orderUpdateSuccess({accountResponse: payload, success: allSucceeded});
          } else {
            gaActionCreators.createGAEvent({
              category:  gaCategory,
              action: 'Unsuccessful',
              label: payload[0].prodIdAlias
            });
            if (payload[0].refundType && payload[0].refundType === 'R') {
              return custServActionCreators.sendCustservEmailStart(payload[0].custServEmailData);
            } else {
              return actionCreators.orderUpdateError({
                success: false,
                message: 'Error on ' + gaCategory,
              });
            }           
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category:  payload[0].googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload[0].prodIdAlias
          });
          if (payload[0].refundType && payload[0].refundType === 'R') {
            return of(custServActionCreators.sendCustservEmailStart(payload[0].custServEmailData));
          } else {
            return of(
              actionCreators.orderUpdateError({ success: false, message: err }),
            );
          }        
        }),
      );
      const refetchCustomerPayload = {
        accountNumber: payload.accountNumber,
        postalCode: payload.postalCode,
      };
      let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      if (payload[0].intelliselPayload) {
        let intelliselAcceptOffer = of(intelliselActionCreators.intelliselStart(payload[0].intelliselPayload));
        return concat(loading, source$, intelliselAcceptOffer, reloadCust);
      } else {
        return concat(loading, source$, reloadCust);
      }
    }),
  );

  const stopAutoRenew =  (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
      action$.pipe(
        ofType(CANCEL_STOP_AUTO_RENEW_START),
        mergeMap(({ payload }) => {
        const client = new ApiClient();
        const clientConfig = state$.value.clientConfig.clientConfig;
        const loading = of(actionCreators.cancelStopAutoRenewLoading(true));

        const promises = payload.map(stopAutoRenewal => {
          let putBody: StopAutoRenewBody ;
          putBody = {
            prodIdAlias: stopAutoRenewal.prodIdAlias,
            accountNumber: stopAutoRenewal.accountNumber,
            removeFromCurrentSingleAutoRenewal: stopAutoRenewal.stopSingleAutoRenew,
            allowGiftAutoRenewal: stopAutoRenewal.stopGiftAutoRenew,
          };
  
          const apiConfig: ApiConfig = {
            credentialsObject: state$.value.auth.omsAuth.credentials,
            url: clientConfig.url,
            path: STOP_AUTO_RENEW_PATH,
            region: clientConfig.Region,
            requestmethod: 'POST',
            requestbody: putBody,
            parms: {},
            additionalParms: {
              headers: {
                'x-session-id': clientConfig.uuid
              },
            },
          };
          return fromPromise(client.callApiGateway(apiConfig));
        });
        let loggedIncustomer = getLoggedInCustomer(state$.value);
        const refetchCustomerPayload = {
          accountNumber: loggedIncustomer.accountNumber,
          postalCode: loggedIncustomer.postalCode,
        };
        let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
        const source$ = forkJoin(promises, getStopAutoRenewSucceeded).pipe(
          map(responses => {
            let payloadCount = payload.length;
            if (payloadCount === responses.length) {
              gaActionCreators.createGAEvent({
                category: payload[0].googleAnalyticsCategory,
                action: 'Successful',
                label: payload[0].prodIdAlias
              });
              return actionCreators.cancelStopAutoRenewSuccess({payload, responses, success: true});
            } else {
              gaActionCreators.createGAEvent({
                category: payload[0].googleAnalyticsCategory,
                action: 'Unsuccessful',
                label: payload[0].prodIdAlias
              });
              return actionCreators.cancelStopAutoRenewError({
                success: false,
                message: 'Error on Cancel - Stop Auto Renew',
              });
            }
          }),
          catchError(err => {
            gaActionCreators.createGAEvent({
              category:  payload[0].googleAnalyticsCategory,
              action: 'Unknown Error',
              label: payload[0].prodIdAlias
            });
            return of(
              actionCreators.cancelStopAutoRenewError({ success: false, message: err }),
            );
          }),
        );
        return concat(loading, source$, reloadCust);
      }),
    );
    
export const epics = combineEpics(orderUpdate, stopAutoRenew);
