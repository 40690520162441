import {
  ActionsObservable,
  StateObservable,
  ofType,
  combineEpics,
} from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { EVENTBUS_PUBLISH } from '@src/app/eventLogging';
import { mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { CognitoIdentityClient, GetIdCommand } from '@aws-sdk/client-cognito-identity';
import { reject, resolve } from 'promise';

const EVENTBUSTRANSACTIONTYPE = 'eventBusTransactionType';
const EVENTBUSPOSTBODY = 'eventBusPostBody';

const publishEvent = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(EVENTBUS_PUBLISH),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      let credentialsObject = state$.value.auth.omsAuth.credentials;
      const identityPoolId =
        state$.value.clientConfig.clientConfig.identityPoolId;
      
      let newPayload = {};
      let newEventData = {
        'category': payload.category,
        'action': payload.action,
        'label': payload.label ? payload.label : '',
        'value': payload.value ? payload.value : ''
      };
      newPayload[EVENTBUSTRANSACTIONTYPE] = 'PUBLISH';
      newPayload[EVENTBUSPOSTBODY] = newEventData;
      const client = new ApiClient();

      if (!credentialsObject) {
        const input = {
          IdentityPoolId: identityPoolId,
        };
  
        const cognitoIdentityClient = new CognitoIdentityClient({ region: clientConfig.Region });
        const command = new GetIdCommand(input);
        cognitoIdentityClient.send(command, (err: any, creds: any) => {
          if (err) {
            console.log(err);
            reject(err);
          } else {
            credentialsObject = {
              IdentityId: creds.IdentityId,
            };
            const apiConfig: ApiConfig = {
              credentialsObject: credentialsObject,
              url: clientConfig.url,
              path: '/eventbuspublish',
              region: clientConfig.Region,
              requestmethod: 'POST',
              requestbody: newPayload,
              parms: {},
              additionalParms: {
                headers: {
                  'x-session-id': clientConfig.uuid
                },
              },
            };
            client.callApiGateway(apiConfig);
  
            resolve(creds);
          }
        });
        return EMPTY;  
      } else {
        const apiConfig: ApiConfig = {
          credentialsObject: credentialsObject,
          url: clientConfig.url,
          path: '/eventbuspublish',
          region: clientConfig.Region,
          requestmethod: 'POST',
          requestbody: newPayload,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        client.callApiGateway(apiConfig);
  
        return EMPTY;  
      }
    }),
  );

export const epics = combineEpics(publishEvent);
