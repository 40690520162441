import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from '@src/app/_redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { HeaderNav } from '@src/app/header/HeaderNav';
import { UserDropDown } from '@src/app/header/UserDropDown';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';
import './header.scss';
import { cognitoLogout } from '@src/app/login/cognito/actions';
import { getAdminConfigTableItem } from '@src/app/_global/utility/adminConfigUtils';
import { bindActionCreators } from 'redux';
import { actionCreators } from '@src/app/user/actions';

const NoLogoHeader: React.SFC<any> = (props) => {
  return (
    <>
      <div className="client-no-logo container">
        {props.isAuth ? (
          <div className="main-nav-toggle" onClick={() => props.onClickLeftNavOpen()}>
            <i className="pull-left fa fa-bars" />
          </div>
        ) : null}

          {props.isAuth && !props.directViewLogin ? (
            <div className="no-logo user-info">
              <div>
                <UserDropDown
                  userName={props.userName.address.name}
                  onClick={() => props.onLogout()}
                  myloLogin={props.myloLogin}
                  myloUrl={props.myloUrl} 
                  className="user-drop-btn" />
              </div>
            </div>
          ) : null}

      </div>
    </>
  );
};
const RegularLogoHeader: React.SFC<any> = (props) => {
  return (
    <>
      <div className="client-logo container">
        {props.isAuth ? (
          <div className="main-nav-toggle" onClick={() => props.onClickLeftNavOpen()}>
            <i className="pull-left fa fa-bars" />
          </div>
        ) : null}
          <div 
            className="logo-wrapper"
            title={props.logoAltText}
          >
            <img  
              src={props.logoURL}
              alt={props.logoAltText}
              className="logo-image no-print"
            />
          </div>
        <div className="user-info">
          {props.isAuth && !props.directViewLogin ? (
            <UserDropDown
              userName={props.userName.address.name}
              onClick={() => props.onLogout()}
              myloLogin={props.myloLogin}
              myloUrl={props.myloUrl}
              className="user-drop-btn"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
const WideLogoHeader: React.SFC<any> = (props) => {
  return (
    <>
      <div className="header-top-section">

        <div className="client-logo-wide">

          <div 
            className=""
            title={props.logoAltText}
          >
            <img  
              src={props.logoURL}
              alt={props.logoAltText}
              className="logo-image-wide no-print"
            />
          </div>
        </div>
      </div>
      <div className="header-bottom-section">
        {props.isAuth ? (
              <div className="main-nav-toggle-wide-logo" onClick={() => props.onClickLeftNavOpen()}>
                <i className="pull-left fa fa-bars" />
              </div>
            ) : null}
        {props.isAuth && !props.directViewLogin ? (
            <div className="user-info-wide">
              <UserDropDown
                userName={props.userName.address.name}
                onClick={() => props.onLogout()}
                myloLogin={props.myloLogin}
                myloUrl={props.myloUrl}
                className="user-drop-btn"
              />
            </div>
        ) : null}
      </div>
    </>
  );
};
const CenteredLogoHeader: React.SFC<any> = (props) => {
  let clientLogoClass = props.isAuth ? 'client-logo-centered-auth container' : 'client-logo-centered container';
  return (
    <>
      <div className="header-top-section">
        {props.isAuth ? (
            <div className="main-nav-toggle-centered-logo" onClick={() => props.onClickLeftNavOpen()}>
              <i className="fa fa-bars" />
            </div>
          ) : null}
        <div className={clientLogoClass}>
          <div 
            className="logo-wrapper-centered"
            title={props.logoAltText}
          >
            <img  
              src={props.logoURL}
              alt={props.logoAltText}
              className="logo-image-centered no-print"
            />
          </div>
        </div>
        <div className="header-bottom-section">
          {props.isAuth  && !props.directViewLogin ? (
            <div className="user-info">
              <UserDropDown
                userName={props.userName.address.name}
                onClick={() => props.onLogout()}
                myloLogin={props.myloLogin}
                myloUrl={props.myloUrl}
                className="user-drop-btn-center"
              />
            </div>
          ) : null}
          </div>
      </div>
    </>
  );
};
interface Props extends RouteComponentProps {
  userName: any;
  isAuth: boolean;
  logout: any;
  headerBanner?: any;
  mobileHeaderBanner?: any;
  bannerAltText?: any;
  logo?: any;
  logoAltText?: any;
  assetUrl?: any;
  myloLogin: boolean;
  myloConfig: any;
  clientConfig: any;
  adminConfig: any;
  logoUrl?: any;
  leftNavOpen: boolean;
  leftNavOpenAction: any;
  myloPath: any;
  fullWidthLogo: any;
  centerLogo: any;
  centerMainNav: any;
  directViewLogin: boolean;
}
const setupTopNavClass = centeredNav => {
  if (centeredNav) {
    return 'top-nav-section-centered no-print';
  } else {
    return 'no-print';
  }
};
export const Header: React.SFC<Props> = props => {
  const onLogout = () => {
    const { history, myloLogin, myloConfig } = props;
    history.push('/login');
    cognitoLogout(myloLogin, myloConfig);
  };
  const onClickLeftNavOpen = () => {
    props.leftNavOpenAction({payload: !props.leftNavOpen});
  };
  let pageHeaderAdminData = getAdminConfigTableItem(
    props.adminConfig,
    props.clientConfig,
    'PageHeader',
    props.clientConfig.clientConfig.magCode,
  );
  const myloUrl = `${props.myloConfig.myloUiBaseUrl}${props.myloPath}?client_id=${props.myloConfig.clientId}`;
  const logoURL = props.logoUrl ? props.logoUrl : props.assetUrl + '/' + props.logo;
  const bannerURL = props.assetUrl + '/' + props.headerBanner;
  const mobileBannerURL = props.assetUrl + '/' + props.mobileHeaderBanner;
  return (
    <header className="main-header">
        <div className="header-inner">
          {/* No Logo Header */}
          {!props.logoUrl && !props.logo && (
            <NoLogoHeader 
              onClickLeftNavOpen={onClickLeftNavOpen}
              onLogout={onLogout} 
              myloUrl={myloUrl} 
              isAuth={props.isAuth}
              userName={props.userName}
              fullWidthLogo={props.fullWidthLogo}
              myloLogin={props.myloLogin}
              directViewLogin={props.directViewLogin}
            />
          )}
          {/* Header with Plain Logo, NOT Centered */}
          {(props.logoUrl || props.logo) && !props.centerLogo && !props.fullWidthLogo && (
            <RegularLogoHeader
              onClickLeftNavOpen={onClickLeftNavOpen}
              onLogout={onLogout} 
              myloUrl={myloUrl} 
              isAuth={props.isAuth}
              logoURL={logoURL}
              logoAltText={props.logoAltText}
              myloLogin={props.myloLogin}
              userName={props.userName}
              directViewLogin={props.directViewLogin}
            />
          )}
          {/* Header with WIDE Logo */}
          {(props.logoUrl || props.logo) && props.fullWidthLogo && !props.centerLogo && (
            <WideLogoHeader
              onClickLeftNavOpen={onClickLeftNavOpen}
              onLogout={onLogout} 
              myloUrl={myloUrl} 
              myloLogin={props.myloLogin}
              isAuth={props.isAuth}
              logoURL={logoURL}
              logoAltText={props.logoAltText}
              userName={props.userName}
              directViewLogin={props.directViewLogin}
            />
          )}
          {/* Header with WIDE Logo */}
          {(props.logoUrl || props.logo) && props.centerLogo && !props.fullWidthLogo && (
            <CenteredLogoHeader
              onClickLeftNavOpen={onClickLeftNavOpen}
              onLogout={onLogout} 
              myloUrl={myloUrl} 
              myloLogin={props.myloLogin}
              isAuth={props.isAuth}
              logoURL={logoURL}
              logoAltText={props.logoAltText}
              userName={props.userName}
              directViewLogin={props.directViewLogin}
            />
          )}

          {pageHeaderAdminData.Navigation && pageHeaderAdminData.Navigation.length > 0 && !props.directViewLogin && (
            <div className={setupTopNavClass(props.centerMainNav)}>
              <HeaderNav
                mode="horizontal"
                openAnimation="slide-up"
                clientConfig={props.clientConfig}
                adminConfig={props.adminConfig}
              />
            </div>
          )}
          <div className="full-banner-wrapper">
            {props.headerBanner && (
              <div
                className="banner-image"
                title={props.bannerAltText}
              >
                <img  
                  src={bannerURL}
                  alt={props.bannerAltText}
                  className="banner-image no-print"
                />
              </div>
            )}
          </div>
          <div className="mobile-banner-wrapper">
            {props.mobileHeaderBanner ? (
              <div
                className="mobile-banner-image"
                title={props.bannerAltText}
              >
                <img  
                  src={mobileBannerURL}
                  alt={props.bannerAltText}
                  className="mobile-banner-image no-print"
                />
              </div>
            ) : (
              props.headerBanner && (
                <div
                  className="banner-image"
                  title={props.bannerAltText}
                >
                  <img  
                    src={bannerURL}
                    alt={props.bannerAltText}
                    className="banner-image no-print"
                  />
                </div>
              )
            )}
          </div>
        </div>
    </header>
  );
};

const mapState = (state: RootState) => {
  let pageHeader = getAdminConfigTableItem(
    state.adminConfig,
    state.clientConfig,
    'PageHeader',
  );
  let availActions = getAdminConfigTableItem(
    state.adminConfig,
    state.clientConfig,
    'AvailableActions',
  );
  return {
    userName: getLoggedInCustomer(state) || 'Not Found',
    isAuth: state.auth.omsAuth.isLoginSuccessful,
    logo: pageHeader.LogoPath || null,
    logoAltText: pageHeader.LogoAltText || null,
    logoUrl: pageHeader.LogoUrl || null,
    headerBanner: pageHeader.BannerPath || null,
    mobileHeaderBanner: pageHeader.MobileBannerPath || null,
    bannerAltText: pageHeader.BannerAltText || null,
    fullWidthLogo: pageHeader.FullWidthLogo || false,
    myloLogin: state.auth.omsAuth.credentials && state.auth.omsAuth.credentials.Logins,
    myloConfig: state.clientConfig.clientConfig.myloConfig,
    assetUrl: state.clientConfig.clientConfig.userFilesBucket,
    clientConfig: state.clientConfig,
    adminConfig: state.adminConfig,
    leftNavOpen:  state.userState.leftNavOpen,
    myloPath: availActions.Preferences && availActions.Preferences.myloAccountCustomPath || '/account',
    centerLogo: pageHeader.CenterLogo,
    centerMainNav: pageHeader.CenterMainNav,
    directViewLogin: state.userState.directViewLogin,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      leftNavOpenAction: actionCreators.leftNavOpen,
    },
    dispatch,
  );

export default connect(mapState, mapDispatchToProps)(withRouter(Header) as any);
